<template>
  <div>
    <app-header :routes="routes" />
    <Main>
      <shipments
        :showTitle="false"
      />
    </Main>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import Shipments from '@/components/shipments/Shipments';

export default defineComponent({
  name: 'AgentShipments',
  components: {
    Main,
    Shipments
  },
  setup() {
    const routes = [{
      name: 'AgentShipments',
      breadcrumbName: 'views.shipmentRequest.shipments'
    }];

    return {
      routes
    };
  }
});
</script>
